import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Button, FieldNumber, FieldRadio, FieldRange, FieldSelect, FieldText, FieldTextArea, Heading, Line, useApp, useUpdateEffect } from '@dex/bubl-dash';
import TestSessionsEditTabsPanel from './TestSessionsEditTabsPanel';
import { fixedNum, get, loop, set } from '@dex/bubl-helpers';
import TestSessionsApi from '../TestSessionsApi';
import { getPaceFromKph } from '../../../functions/sharedTests';

const TestSessionsEditTabsSummary: React.FC<any> = (props: any) => {

    const { form, data, process, handleSubmit } = props;

    const app = useApp();

    const [settings] = app.store("appSettings");

    const summaryOptions = [
        {
            label: "VO2max Cycling",
            value: "VO2maxCyclingSessionSummary",
        },
        {
            label: "VO2max Running",
            value: "VO2maxCyclingSessionSummary",
        },
        {
            label: "Met Cycling",
            value: "MetCyclingSessionSummary",
        },
        {
            label: "Met Running",
            value: "MetRunningSessionSummary",
        },
        {
            label: "Sprint Cycling",
            value: "sprintCyclingSessionSummary",
        },
        {
            label: "Sprint Running",
            value: "sprintRunningSessionSummary",
        },
    ];

    const forwardOptions = [
        {
            label: "VO2max Cycling",
            value: "VO2maxCyclingGoingForward",
        },
        {
            label: "VO2max Running",
            value: "VO2maxRunningGoingForward",
        },
        {
            label: "Met Cycling",
            value: "MetCyclingGoingForward",
        },
        {
            label: "Met Running",
            value: "MetRunningGoingForward",
        },
        {
            label: "Sprint Cycling",
            value: "sprintCyclingGoingForward",
        },
        {
            label: "Sprint Running",
            value: "sprintRunningGoingForward",
        },
    ];


    const useDefaultText = useCallback((index, name: string) => {

        let text = get(settings.testSessions, index);

        const values = form.values;

        set(values, name, text);

        form.reset(values);

    }, [props, form.hash]);

    const { hasCycling, hasRaceReady, hasRunning, hasVo2max } = useMemo(() => {

        let hasRunning = false;
        let hasCycling = false;
        let hasRaceReady = false;
        let hasVo2max = false;

        data.labTests.map((labTest) => {

            if (labTest.activity === "Running") hasRunning = true;
            if (labTest.activity === "Cycling") hasCycling = true;
            if (labTest.type === "VO2max") hasVo2max = true;
            if (labTest.type === "Race Ready") hasRaceReady = true;

        });

        return { hasCycling, hasRaceReady, hasRunning, hasVo2max }

    }, [data]);

    const ratings = [
        { key: "health", label: "Health" },
        { key: "bodyComp", label: "Body Comp" },
        { key: "fitness", label: "Fitness" },
        { key: "circulation", label: "Blood Circulation" },
        { key: "breathing", label: "Breathing" },
        { key: "muscle", label: "Muscle Fitness" },
        { key: "energy", label: "Energy Metabolism" },
        hasRunning ? { key: "speed", label: "Speed" } : null,
        hasCycling ? { key: "power", label: "Power" } : null,
    ].filter(Boolean);

    const getActivitySummary = TestSessionsApi.update(data.id, "activitySummary", {
        onComplete: (data) => {

            app.alert('Summary Calculation Success', 'success');

            if (data.cycling) {
                form.handleChange({ reset: true, name: "summary.cycling", value: data.cycling });
            }

            if (data.running) {
                form.handleChange({ reset: true, name: "summary.running", value: data.running });
            }

        }
    });

    const handleSummarySubmit = useCallback((activity) => {
        getActivitySummary.run({ data: { activity: activity } });
    }, [form]);

    const runningSummary = form.values.summary.running;
    const cyclingSummary = form.values.summary.cycling;
    const weight = form.values.bio.weight;

    useUpdateEffect(() => {
        form.handleChange({ reset: true, name: "summary.running.maxAerobicPace", value: getPaceFromKph(runningSummary.maxAerobicSpeed) + " min/km" });
    }, [get(runningSummary, "maxAerobicSpeed")]);

    useUpdateEffect(() => {
        form.handleChange({ reset: true, name: "summary.running.thresholdPace", value: getPaceFromKph(runningSummary.thresholdSpeed) + " min/km" });
    }, [get(runningSummary, "thresholdSpeed")]);

    useUpdateEffect(() => {
        form.handleChange({ reset: true, name: "summary.running.tempoOnsetPace", value: getPaceFromKph(runningSummary.tempoOnsetSpeed) + " min/km" });
    }, [get(runningSummary, "tempoOnsetSpeed")]);

    useUpdateEffect(() => {
        form.handleChange({ reset: true, name: "summary.running.fatMaxBurnPace", value: getPaceFromKph(runningSummary.fatMaxBurnSpeed) + " min/km" });
    }, [get(runningSummary, "fatMaxBurnSpeed")]);

    useUpdateEffect(() => {
        form.handleChange({ reset: true, name: "summary.cycling.maxAerobicPace", value: fixedNum((cyclingSummary.maxAerobicSpeed / weight), 1) + " W/kg" });
    }, [get(cyclingSummary, "maxAerobicSpeed")]);

    useUpdateEffect(() => {
        form.handleChange({ reset: true, name: "summary.cycling.thresholdPace", value: fixedNum((cyclingSummary.thresholdSpeed / weight), 1) + " W/kg" });
    }, [get(cyclingSummary, "thresholdSpeed")]);

    useUpdateEffect(() => {
        form.handleChange({ reset: true, name: "summary.cycling.tempoOnsetPace", value: fixedNum((cyclingSummary.tempoOnsetSpeed / weight), 1) + " W/kg" });
    }, [get(cyclingSummary, "tempoOnsetSpeed")]);

    useUpdateEffect(() => {
        form.handleChange({ reset: true, name: "summary.cycling.fatMaxBurnPace", value: fixedNum((cyclingSummary.fatMaxBurnSpeed / weight), 1) + " W/kg" });
    }, [get(cyclingSummary, "fatMaxBurnSpeed")]);

    return useMemo(() => (

        <TestSessionsEditTabsPanel
            heading={"Summary"}
            {...props}
        >

            <Row gutter={16} edge={true}>

                {ratings.map((rating: any) => (
                    <Col key={rating.key} col={{ xs: 24, md: 8, lg: 6 }}>

                        <FieldRange
                            form={form}
                            label={rating.label}
                            name={`summary.ratings.${rating.key}`}
                            required={false}
                            defaultValue={get(form, `defaults.summary.ratings.${rating.key}`)}
                            min={0}
                            max={5}
                            step={.5}
                        />
                    </Col>
                ))}

            </Row>

            <Line />

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, lg: 16 }}>

                    <FieldTextArea
                        form={form}
                        label={"Session Summary"}
                        name={'summary.comments'}
                        required={false}
                        defaultValue={form.defaults.summary.comments}
                        rows={14}
                        maxLength={10000}
                    />

                </Col>

                <Col col={{ xs: 24, lg: 8 }}>

                    <Space />

                    <Row edge={true}>


                        <Col col={{ xs: 24 }}>

                            <FieldSelect
                                form={form}
                                name="summary.defaultTextSummary"
                                options={summaryOptions}
                            />

                        </Col>

                        <Button
                            label={'Use Default Text'}
                            onClick={useDefaultText.bind(null, get(form.values, `summary.defaultTextSummary`), "summary.comments")}
                            type={'faded'}
                            confirm={form.values.summary.comments ? { title: 'Are you sure?', message: 'Are you sure you want to overwrite the current summary' } : undefined}
                        />

                    </Row>

                    <Space />

                </Col>

                <Col col={{ xs: 24 }}>
                    <Space />
                </Col>

                <Col col={{ xs: 12 }} className={`table-bdr-right-gray`}>
                    <Heading space={null}>
                        <Row edge={true} align='center'>
                            <Col justify='start' col={{ xs: 16 }}>
                                Running Test Summary
                            </Col>
                            <Col align='end' col={{ xs: 8 }}>
                                <Button
                                    label="calculate"
                                    size='small'
                                    type='faded'
                                    onClick={() => handleSummarySubmit("Running")}
                                />
                            </Col>
                        </Row>
                    </Heading>

                    <Row edge gutter={8}>
                        <Col col={{ xs: 12 }}>
                            <FieldNumber
                                form={form}
                                label={"VO2"}
                                name={'summary.running.vo2'}
                                defaultValue={form.defaults.summary.running?.vo2}
                                dynamicKey
                                wrapClass={"manual-input"}
                                suffix={" ml/kg*min"}
                            />
                            <FieldRadio
                                form={form}
                                name={'summary.running.vo2Source'}
                                options={[
                                    { label: "Max", value: "Max" },
                                    { label: "Peak", value: "Peak" }
                                ]}
                                defaultValue={form.defaults.summary.running?.vo2Source}
                                key={form.defaults.summary.running?.vo2Source}
                                wrapClass={"small"}
                            />
                        </Col>

                        <Col col={{ xs: 12 }}>
                            <FieldNumber
                                form={form}
                                label={"HR"}
                                name={'summary.running.hr'}
                                defaultValue={form.defaults.summary.running?.hr}
                                dynamicKey
                                wrapClass={"manual-input"}
                                suffix={" bpm"}
                            />
                            <FieldRadio
                                form={form}
                                name={'summary.running.hrSource'}
                                defaultValue={form.defaults.summary.running?.hrSource}
                                key={form.defaults.summary.running?.hrSource}
                                options={[
                                    { label: "Max", value: "Max" },
                                    { label: "Peak", value: "Peak" }
                                ]}
                                wrapClass={"small"}
                            />
                        </Col>

                        <Col col={{ xs: 12 }}>
                            <FieldNumber
                                form={form}
                                label={"Max Aerobic"}
                                name={'summary.running.maxAerobicSpeed'}
                                defaultValue={form.defaults.summary.running?.maxAerobicSpeed}
                                dynamicKey
                                wrapClass={"manual-input"}
                                suffix={" kph"}
                            />
                            <FieldText
                                form={form}
                                name={'summary.running.maxAerobicPace'}
                                defaultValue={form.defaults.summary.running?.maxAerobicPace}
                                key={form.values.summary.running?.maxAerobicSpeed}
                                dynamicKey
                                disabled
                            />
                        </Col>

                        <Col col={{ xs: 12 }}>
                            <FieldNumber
                                form={form}
                                label={"Threshold/FTP"}
                                name={'summary.running.thresholdSpeed'}
                                defaultValue={form.defaults.summary.running?.thresholdSpeed}
                                dynamicKey
                                wrapClass={"manual-input"}
                                suffix={" kph"}
                            />
                            <FieldText
                                form={form}
                                name={'summary.running.thresholdPace'}
                                defaultValue={form.defaults.summary.running?.thresholdPace}
                                key={form.values.summary.running?.thresholdSpeed}
                                dynamicKey
                                disabled
                            />
                        </Col>

                        <Col col={{ xs: 12 }}>
                            <FieldNumber
                                form={form}
                                label={"Tempo Onset"}
                                name={'summary.running.tempoOnsetSpeed'}
                                defaultValue={form.defaults.summary.running?.tempoOnsetSpeed}
                                dynamicKey
                                wrapClass={"manual-input"}
                                suffix={" kph"}
                            />
                            <FieldText
                                form={form}
                                name={'summary.running.tempoOnsetPace'}
                                defaultValue={form.defaults.summary.running?.tempoOnsetPace}
                                key={form.values.summary.running?.tempoOnsetSpeed}
                                dynamicKey
                                disabled
                            />
                        </Col>

                        <Col col={{ xs: 12 }}>
                            <FieldNumber
                                form={form}
                                label={"Fat Max Burn"}
                                name={'summary.running.fatMaxBurnSpeed'}
                                defaultValue={form.defaults.summary.running?.fatMaxBurnSpeed}
                                dynamicKey
                                wrapClass={"manual-input"}
                                suffix={" kph"}
                            />
                            <FieldText
                                form={form}
                                name={'summary.running.fatMaxBurnPace'}
                                defaultValue={form.defaults.summary.running?.fatMaxBurnPace}
                                key={form.values.summary.running?.fatMaxBurnSpeed}
                                dynamicKey
                                disabled
                            />
                        </Col>

                    </Row>

                </Col>

                <Col col={{ xs: 12 }}>
                    <Heading space={null} >
                        <Row edge={true} align='center'>
                            <Col justify='start' col={{ xs: 16 }}>
                                Cycling Test Summary
                            </Col>
                            <Col align='end' col={{ xs: 8 }}>
                                <Button
                                    label="calculate"
                                    size='small'
                                    type='faded'
                                    onClick={() => handleSummarySubmit("Cycling")}
                                />
                            </Col>
                        </Row>
                    </Heading>

                    <Row edge gutter={8}>
                        <Col col={{ xs: 12 }}>
                            <FieldNumber
                                form={form}
                                label={"VO2"}
                                name={'summary.cycling.vo2'}
                                defaultValue={form.defaults.summary.cycling?.vo2}
                                dynamicKey
                                wrapClass={"manual-input"}
                                suffix={" ml/kg*min"}
                            />
                            <FieldRadio
                                form={form}
                                name={'summary.cycling.vo2Source'}
                                defaultValue={form.defaults.summary.cycling?.vo2Source}
                                key={form.defaults.summary.cycling?.vo2Source}
                                options={[
                                    { label: "Max", value: "Max" },
                                    { label: "Peak", value: "Peak" }
                                ]}
                                wrapClass={"small"}
                            />
                        </Col>

                        <Col col={{ xs: 12 }}>
                            <FieldNumber
                                form={form}
                                label={"HR"}
                                name={'summary.cycling.hr'}
                                defaultValue={form.defaults.summary.cycling?.hr}
                                dynamicKey
                                wrapClass={"manual-input"}
                                suffix={" bpm"}
                            />
                            <FieldRadio
                                form={form}
                                name={'summary.cycling.hrSource'}
                                defaultValue={form.defaults.summary.cycling?.hrSource}
                                key={form.defaults.summary.cycling?.hrSource}
                                options={[
                                    { label: "Max", value: "Max" },
                                    { label: "Peak", value: "Peak" }
                                ]}
                                wrapClass={"small"}
                            />
                        </Col>

                        <Col col={{ xs: 12 }}>
                            <FieldNumber
                                form={form}
                                label={"Max Aerobic"}
                                name={'summary.cycling.maxAerobicSpeed'}
                                defaultValue={form.defaults.summary.cycling?.maxAerobicSpeed}
                                dynamicKey
                                wrapClass={"manual-input"}
                                suffix={" W"}
                            />
                            <FieldText
                                form={form}
                                name={'summary.cycling.maxAerobicPace'}
                                defaultValue={form.defaults.summary.cycling?.maxAerobicPace}
                                key={form.values.summary.cycling?.maxAerobicSpeed}
                                dynamicKey
                                disabled
                            />
                        </Col>

                        <Col col={{ xs: 12 }}>
                            <FieldNumber
                                form={form}
                                label={"Threshold/FTP"}
                                name={'summary.cycling.thresholdSpeed'}
                                defaultValue={form.defaults.summary.cycling?.thresholdSpeed}
                                dynamicKey
                                wrapClass={"manual-input"}
                                suffix={" W"}
                            />
                            <FieldText
                                form={form}
                                name={'summary.cycling.thresholdPace'}
                                defaultValue={form.defaults.summary.cycling?.thresholdPace}
                                key={form.values.summary.cycling?.thresholdSpeed}
                                dynamicKey
                                disabled
                            />
                        </Col>

                        <Col col={{ xs: 12 }}>
                            <FieldNumber
                                form={form}
                                label={"Tempo Onset"}
                                name={'summary.cycling.tempoOnsetSpeed'}
                                defaultValue={form.defaults.summary.cycling?.tempoOnsetSpeed}
                                dynamicKey
                                wrapClass={"manual-input"}
                                suffix={" W"}
                            />
                            <FieldText
                                form={form}
                                name={'summary.cycling.tempoOnsetPace'}
                                defaultValue={form.defaults.summary.cycling?.tempoOnsetPace}
                                key={form.values.summary.cycling?.tempoOnsetSpeed}
                                dynamicKey
                                disabled
                            />
                        </Col>

                        <Col col={{ xs: 12 }}>
                            <FieldNumber
                                form={form}
                                label={"Fat Max Burn"}
                                name={'summary.cycling.fatMaxBurnSpeed'}
                                defaultValue={form.defaults.summary.cycling?.fatMaxBurnSpeed}
                                dynamicKey
                                wrapClass={"manual-input"}
                                suffix={" W"}
                            />
                            <FieldText
                                form={form}
                                name={'summary.cycling.fatMaxBurnPace'}
                                defaultValue={form.defaults.summary.cycling?.fatMaxBurnPace}
                                key={form.values.summary.cycling?.fatMaxBurnSpeed}
                                dynamicKey
                                disabled
                            />
                        </Col>

                    </Row>

                </Col>

                <Col col={{ xs: 24 }}>
                    <Line space={false} />
                </Col>

                <Col col={{ xs: 24, lg: 16 }}>

                    <FieldTextArea
                        form={form}
                        label={"Going Forward"}
                        name={'summary.goingForward'}
                        required={false}
                        defaultValue={form.defaults.summary.goingForward}
                        rows={14}
                        maxLength={8000}
                    />

                </Col>

                <Col col={{ xs: 24, lg: 8 }}>

                    <Space />

                    <Row edge={true}>


                        <Col col={{ xs: 24 }}>

                            <FieldSelect
                                form={form}
                                name="summary.defaultTextForward"
                                options={forwardOptions}
                            />

                        </Col>

                        <Button
                            label={'Use Default Text'}
                            onClick={useDefaultText.bind(null, get(form.values, `summary.defaultTextForward`), "summary.goingForward")}
                            type={'faded'}
                            confirm={form.values.summary.goingForward ? { title: 'Are you sure?', message: 'Are you sure you want to overwrite the current summary' } : undefined}
                        />

                    </Row>

                    <Space />

                </Col>

            </Row>


        </TestSessionsEditTabsPanel>

    ), [form.hash, getActivitySummary]);

}

export default TestSessionsEditTabsSummary;
