import React, { useCallback, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Alert, Button, ButtonRow, DataTableCell, FieldCheckbox, FieldRelationship, FieldSelect, InputTable, InputTableCell, InputTableRow, Line, Panel, Seperator, Space, useApp } from '@dex/bubl-dash';
import TestSessionsEditTabsPanel from './TestSessionsEditTabsPanel';
import { get, loop } from '@dex/bubl-helpers';

const TestSessionsEditTabsReport: React.FC<any> = (props: any) => {

    const { form, data, process, handleSubmit } = props;

    const app = useApp();

    let hasRunning = false;
    let hasCycling = false;
    let hasRaceReady = false;
    let hasVo2max = false;
    let hasPeak = false;
    let hasMET = false;
    let hasRMR = false;
    let hasGlycogen = false;
    let hasPerformance = false;
    let hasSprint = false;
    let hasSweat = false;

    data.labTests.map((labTest) => {

        if (labTest.activity === "Running") hasRunning = true;
        if (labTest.activity === "Cycling") hasCycling = true;
        if (labTest.type === "VO2max") hasVo2max = true;
        if (labTest.type === "MET") hasMET = true;
        if (labTest.type === "RMR") hasRMR = true;
        if (labTest.type === "Glycogen Depletion") hasGlycogen = true;
        if (labTest.type === "Performance") hasPerformance = true;
        if (labTest.type === "Sprint") hasSprint = true;
        if (labTest.type === "Sweat Test") hasSweat = true;
        if (labTest.type === "Race Ready") hasRaceReady = true;
        if (labTest.intensity === "Peak") hasPeak = true;

    });

    const reports: any = [
        hasRunning && hasVo2max ? {
            key: "vo2max-running",
            heading: "V02max Running Report",
            filters: { activity: "Running", type: "VO2max", intensity: "Submax" },
            pages: [
                { value: "cover", label: "Cover" },
                { value: "athlete-profile", label: "Athlete Profile" },
                { value: "body-composition", label: "Body Composition" },
                { value: "fitness-level", label: "Fitness Level" },
                { value: "circulation", label: "Circulation" },
                { value: "breathing", label: "Breathing" },
                { value: "muscle-fitness", label: "Muscle Fitness" },
                { value: "energy", label: "Energy" },
                { value: "performance", label: "Performance" },
                { value: "training-zones", label: "Training Zones" },
                { value: "summary", label: "Summary" },
                { value: "comment", label: "Appraiser's Comments" },
            ],
        } : null,
        hasRunning && hasVo2max && hasPeak ? {
            key: "vo2max-running-peak",
            heading: "V02max Running Peak Report",
            filters: { activity: "Running", type: "VO2max", intensity: "Peak" },
            pages: [
                { value: "cover", label: "Cover" },
                { value: "athlete-profile", label: "Athlete Profile" },
                { value: "body-composition", label: "Body Composition" },
                { value: "fitness-level", label: "Fitness Level" },
                { value: "circulation", label: "Circulation" },
                { value: "breathing", label: "Breathing" },
                { value: "muscle-fitness", label: "Muscle Fitness" },
                { value: "energy", label: "Energy" },
                { value: "performance", label: "Performance" },
                { value: "training-zones", label: "Training Zones" },
                { value: "summary", label: "Summary" },
                { value: "comment", label: "Appraiser's Comments" },
            ],
        } : null,
        hasCycling && hasVo2max ? {
            key: "vo2max-cycling",
            heading: "V02max Cycling Report",
            filters: { activity: "Cycling", type: "VO2max" },
            pages: [
                { value: "cover", label: "Cover" },
                { value: "athlete-profile", label: "Athlete Profile" },
                { value: "body-composition", label: "Body Composition" },
                { value: "fitness-level", label: "Fitness Level" },
                { value: "circulation", label: "Circulation" },
                { value: "breathing", label: "Breathing" },
                { value: "muscle-fitness", label: "Muscle Fitness" },
                { value: "energy", label: "Energy" },
                { value: "performance", label: "Performance" },
                { value: "training-zones", label: "Training Zones" },
                { value: "summary", label: "Summary" },
                { value: "comment", label: "Appraiser's Comments" },
            ],
        } : null,
        hasRunning && hasRaceReady ? {
            key: "raceready-running",
            heading: "Race Ready Running Report",
            filters: { activity: "Running", type: "Race Ready", intensity: "Submax" },
            pages: [
                { value: "cover", label: "Cover" },
                { value: "athlete-profile", label: "Athlete Profile" },
                { value: "body-composition", label: "Body Composition" },
                { value: "test-result", label: "Test Result" },
                { value: "performance-rating", label: "Performance Rating" },
                { value: "comment", label: "Appraiser's Comments" },
            ],
        } : null,
        hasRunning && hasRaceReady && hasPeak ? {
            key: "raceready-running-peak",
            heading: "Race Ready Running Peak Report",
            filters: { activity: "Running", type: "Race Ready", intensity: "Peak" },
            pages: [
                { value: "cover", label: "Cover" },
                { value: "athlete-profile", label: "Athlete Profile" },
                { value: "body-composition", label: "Body Composition" },
                { value: "test-result", label: "Test Result" },
                { value: "performance-rating", label: "Performance Rating" },
                { value: "comment", label: "Appraiser's Comments" },
            ],
        } : null,
        hasCycling && hasRaceReady ? {
            key: "raceready-cycling",
            heading: "Race Ready Cycling Report",
            filters: { activity: "Cycling", type: "Race Ready" },
            pages: [
                { value: "cover", label: "Cover" },
                { value: "athlete-profile", label: "Athlete Profile" },
                { value: "body-composition", label: "Body Composition" },
                { value: "test-result", label: "Test Result" },
                { value: "performance-rating", label: "Performance Rating" },
                { value: "comment", label: "Appraiser's Comments" },
            ],
        } : null,
        hasSweat ? {
            key: "sweatanalysis",
            heading: "Sweat Analysis Report",
            filters: { type: "Sweat Test" },
            pages: [
                { value: "cover", label: "Cover" },
                { value: "fluid", label: "Fluid And Sodium Loss" },
                { value: "rehydration", label: "Rehydration" },
                { value: "dehydration", label: "Dehydration" },
                { value: "workout", label: "Workout Details" },
                { value: "comment", label: "Appraiser's Comment" },
                { value: "tip", label: "Tips" },
            ],
        } : null,
        hasRunning && hasMET ? {
            key: "met-running",
            heading: "MET Running Report",
            filters: { activity: "Running", type: "MET", intensity: "Submax" },
            pages: [
                { value: "cover", label: "Cover" },
                { value: "athlete-profile", label: "Athlete Profile" },
                { value: "body-composition", label: "Body Composition" },
                { value: "fitness-level", label: "Fitness Level" },
                { value: "circulation", label: "Circulation" },
                { value: "breathing", label: "Breathing" },
                { value: "muscle-fitness", label: "Muscle Fitness" },
                { value: "energy", label: "Energy" },
                { value: "performance", label: "Performance" },
                { value: "training-zones", label: "Training Zones" },
                { value: "summary", label: "Summary" },
                { value: "comment", label: "Appraiser's Comments" },
            ],
        } : null,
        hasRunning && hasMET && hasPeak ? {
            key: "met-running-peak",
            heading: "MET Running Peak Report",
            filters: { activity: "Running", type: "MET", intensity: "Peak" },
            pages: [
                { value: "cover", label: "Cover" },
                { value: "athlete-profile", label: "Athlete Profile" },
                { value: "body-composition", label: "Body Composition" },
                { value: "fitness-level", label: "Fitness Level" },
                { value: "circulation", label: "Circulation" },
                { value: "breathing", label: "Breathing" },
                { value: "muscle-fitness", label: "Muscle Fitness" },
                { value: "energy", label: "Energy" },
                { value: "performance", label: "Performance" },
                { value: "training-zones", label: "Training Zones" },
                { value: "summary", label: "Summary" },
                { value: "comment", label: "Appraiser's Comments" },
            ],
        } : null,
        hasCycling && hasMET ? {
            key: "met-cycling",
            heading: "MET Cycling Report",
            filters: { activity: "Cycling", type: "MET" },
            pages: [
                { value: "cover", label: "Cover" },
                { value: "athlete-profile", label: "Athlete Profile" },
                { value: "body-composition", label: "Body Composition" },
                { value: "fitness-level", label: "Fitness Level" },
                { value: "circulation", label: "Circulation" },
                { value: "breathing", label: "Breathing" },
                { value: "muscle-fitness", label: "Muscle Fitness" },
                { value: "energy", label: "Energy" },
                { value: "performance", label: "Performance" },
                { value: "training-zones", label: "Training Zones" },
                { value: "summary", label: "Summary" },
                { value: "comment", label: "Appraiser's Comments" },
            ],
        } : null,
        hasRMR ? {
            key: "rmr",
            heading: "RMR Report",
            filters: { type: "RMR" },
            pages: [
                { value: "cover", label: "Cover" },
                { value: "athlete-profile", label: "Athlete Profile" },
                { value: "body-composition", label: "Body Composition" },
                { value: "rmr", label: "Resting Metabolic Rate" },
            ],
        } : null,
        hasGlycogen ? {
            key: "glycogen",
            heading: "Glycogen Depletion Report",
            filters: { type: "Glycogen Depletion" },
            pages: [
                { value: "cover", label: "Cover" },
                { value: "energy-depletion", label: "Energy Depletion" }
            ],
        } : null,
        hasRunning && hasPerformance ? {
            key: "performance-running",
            heading: "Performance Running Report",
            filters: { activity: "Running", type: "Performance" },
            pages: [
                { value: "cover", label: "Cover" },
                { value: "athlete-profile", label: "Athlete Profile" },
                { value: "body-composition", label: "Body Composition" },
                { value: "fitness-level", label: "Fitness Level" },
                { value: "circulation", label: "Circulation" },
                { value: "breathing", label: "Breathing" },
                { value: "muscle-fitness", label: "Muscle Fitness" },
                { value: "energy", label: "Energy" },
                { value: "performance", label: "Performance" },
                { value: "training-zones", label: "Training Zones" },
                { value: "summary", label: "Summary" },
                { value: "comment", label: "Appraiser's Comments" },
            ],
        } : null,
        hasCycling && hasPerformance ? {
            key: "performance-cycling",
            heading: "Performance Cycling Report",
            filters: { activity: "Cycling", type: "Performance" },
            pages: [
                { value: "cover", label: "Cover" },
                { value: "athlete-profile", label: "Athlete Profile" },
                { value: "body-composition", label: "Body Composition" },
                { value: "fitness-level", label: "Fitness Level" },
                { value: "circulation", label: "Circulation" },
                { value: "breathing", label: "Breathing" },
                { value: "muscle-fitness", label: "Muscle Fitness" },
                { value: "energy", label: "Energy" },
                { value: "performance", label: "Performance" },
                { value: "training-zones", label: "Training Zones" },
                { value: "summary", label: "Summary" },
                { value: "comment", label: "Appraiser's Comments" },
            ],
        } : null,
        hasRunning ? {
            key: "performance-tracking-running",
            heading: "Performance Tracking Running Report",
            filters: { activity: "Running", type: ["Performance", "MET", "VO2max"] },
            pages: [
                { value: "cover", label: "Cover" },
                // { value: "athlete-profile", label: "Athlete Profile" },
                { value: "maximum-aerobic-capacity", label: "Maximum Aerobic Capacity" },
                { value: "threshold", label: "Threshold" },
                { value: "metabolic-fficiency", label: "Metabolic Efficiency" },
                { value: "economy", label: "Economy" },
                { value: "zones", label: "Zones" },
                { value: "comment", label: "Appraiser's Comments" },
            ],
        } : null,
        hasCycling ? {
            key: "performance-tracking-cycling",
            heading: "Performance Tracking Cycling Report",
            filters: { activity: "Cycling", type: ["Performance", "MET", "VO2max"] },
            pages: [
                { value: "cover", label: "Cover" },
                // { value: "athlete-profile", label: "Athlete Profile" },
                { value: "maximum-aerobic-capacity", label: "Maximum Aerobic Capacity" },
                { value: "threshold", label: "Threshold" },
                { value: "metabolic-fficiency", label: "Metabolic Efficiency" },
                { value: "economy", label: "Economy" },
                { value: "zones", label: "Zones" },
                { value: "comment", label: "Appraiser's Comments" },
            ],
        } : null,
        hasRunning && hasSprint ? {
            key: "sprint-tracking-running",
            heading: "Sprint Tracking Running Report",
            filters: { activity: "Running", type: ["Sprint"] },
            pages: [
                { value: "cover", label: "Cover" },
                { value: "athlete-profile", label: "Athlete Profile" },
                { value: "sprint", label: "Sprint" },
                { value: "comment", label: "Appraiser's Comments" },
            ],
        } : null,
        hasCycling && hasSprint ? {
            key: "sprint-tracking-cycling",
            heading: "Sprint Tracking Cycling Report",
            filters: { activity: "Cycling", type: ["Sprint"] },
            pages: [
                { value: "cover", label: "Cover" },
                { value: "athlete-profile", label: "Athlete Profile" },
                { value: "sprint", label: "Sprint" },
                { value: "comment", label: "Appraiser's Comments" },
            ],
        } : null,
        hasRunning && hasSprint ? {
            key: "sprint-running",
            heading: "Sprint Running Report",
            filters: { activity: "Running", type: "Sprint" },
            pages: [
                { value: "cover", label: "Cover" },
                { value: "athlete-profile", label: "Athlete Profile" },
                { value: "body-composition", label: "Body Composition" },
                { value: "performance", label: "Performance" },
                { value: "muscle-fitness", label: "Muscle Fitness" },
                { value: "heart-rate", label: "Heart Rate" },
                { value: "summary", label: "Summary" },
            ],
        } : null,
        hasCycling && hasSprint ? {
            key: "sprint-cycling",
            heading: "Sprint Cycling Report",
            filters: { activity: "Cycling", type: "Sprint" },
            pages: [
                { value: "cover", label: "Cover" },
                { value: "athlete-profile", label: "Athlete Profile" },
                { value: "body-composition", label: "Body Composition" },
                { value: "performance", label: "Performance" },
                { value: "muscle-fitness", label: "Muscle Fitness" },
                { value: "heart-rate", label: "Heart Rate" },
                { value: "summary", label: "Summary" },
            ],
        } : null,
    ].filter(Boolean);

    const labTests = useMemo(() => {

        const list: any = [];

        data.labTests.map((labTest) => {

            let label = [labTest.activity, labTest.intensity].filter((e) => e && e != "-").join(", ");

            label = [labTest.type, label].filter(Boolean).join(" - ");

            list.push({ value: labTest.id, label: label, activity: labTest.activity, type: labTest.type, intensity: labTest.intensity });

        });

        return list;

    }, [data.labTests]);

    const filterLabTests = useCallback((labTests, filters) => {

        return labTests.filter((labTest) => {

            let match = true;

            loop(filters, (value, key) => {

                if (Array.isArray(value)) {
                    if (!value.includes(labTest[key])) match = false;
                } else {
                    if (labTest[key] !== value) match = false;
                }

            });

            return match;

        });

    }, []);


    const reportIds = process.data ? process.data.reportIds : data.reportIds;
    const reportLabTestIds = useMemo(() => {

        const reports = process.data ? process.data.reports : data.reports;

        const list: any = {};

        loop(reports, (report, key) => {

            list[key] = report.labTestId;

        })

        return list;

    }, [data, process.data]);

    const options = reports.map((report) => ({ value: report.key, label: report.heading }));
    const enabled = form.values.reportsEnabled || [];

    const handleCopy = useCallback((link) => {

        navigator.clipboard.writeText(link);

        app.alert('Link Copied To Clipboard', 'info');

    }, [data]);

    const path = window.location.origin;

    return useMemo(() => (

        <TestSessionsEditTabsPanel
            heading={"Reports"}
            {...props}
        >

            <FieldCheckbox
                form={form}
                label='Enable Reports'
                name={"reportsEnabled"}
                defaultValue={form.defaults.reportsEnabled}
                options={options}
            />

            <Space height={"small"} />

            <Row gutter={"auto"} edge>

                {reports.map((report, index) => (

                    <React.Fragment key={index}>

                        {enabled.includes(report.key) && (

                            <Col col={{ xs: 24, sm: 12, md: 8 }}>

                                <Panel
                                    className={"panel-inline"}
                                    heading={report.heading}
                                >

                                    <FieldSelect
                                        form={form}
                                        label='Primary Lab Tests'
                                        name={`reports.${report.key}.labTestId`}
                                        defaultValue={get(form.defaults, `reports.${report.key}.labTestId`)}
                                        options={filterLabTests(labTests, report.filters)}
                                    />

                                    {form.values.reports[report.key]?.labTestId &&
                                        <>
                                            <Space height={"small"} />

                                            {reportLabTestIds[report.key] !== form.values.reports[report.key]?.labTestId &&
                                                <Alert type='info' message={"Save to view report links."} showIcon={false} />
                                            }

                                            {reportIds[report.key] && reportLabTestIds[report.key] === form.values.reports[report.key]?.labTestId &&
                                                <>
                                                    <ButtonRow>

                                                        <Button
                                                            label={"View Report"}
                                                            path={`${path}/lab/report/${reportIds[report.key]}`}
                                                            target={"_blank"}
                                                            size={"small"}
                                                            type={"primary"}
                                                        />

                                                        <Button
                                                            label={"Copy Link"}
                                                            onClick={handleCopy.bind(null, `${path}/lab/report/${reportIds[report.key]}`)}
                                                            size={"small"}
                                                            type={"light"}
                                                        />

                                                    </ButtonRow>
                                                </>
                                            }

                                            <Space height={"small"} />

                                            <FieldCheckbox
                                                form={form}
                                                label='Customize Report Pages'
                                                info={"If left unselected will show all pages."}
                                                name={`reports.${report.key}.pages`}
                                                defaultValue={get(form.defaults, `reports.${report.key}.pages`, report.pages.map(page => page.value))}
                                                options={report.pages}
                                                list
                                            />

                                            <FieldRelationship
                                                key={form.values.reports[report.key]?.labTestId}
                                                form={form}
                                                label='Customize Athlete History'
                                                info={"If left unselected will show all past test selected for this report type."}
                                                name={`reports.${report.key}.historyLabTestIds`}
                                                defaultValue={get(form.defaults, `reports.${report.key}.historyLabTestIds`)}
                                                isMulti={true}
                                                endpoint="LabTests"
                                                preload
                                                where={{ athleteId: data.athleteId, date: { lt: data.date } }}
                                            />

                                        </>
                                    }

                                </Panel>

                            </Col>

                        )}

                    </React.Fragment>

                ))}

            </Row>

        </TestSessionsEditTabsPanel>


    ), [props]);

}

export default TestSessionsEditTabsReport;
