import React, { useCallback, useMemo } from 'react';
import LabTestsApi from '../../LabTests/LabTestsApi';
import { Button, ButtonRow, Col, DataTableCell, FieldCheckbox, FieldDate, FieldNumber, FieldRadio, FieldRelationship, FieldTable, FieldText, FieldTextTime, Form, InputTable, InputTableCell, InputTableHead, InputTableHeadCell, InputTableHeadRow, InputTableRow, Panel, Row, Seperator, Space, useUpdateEffect, WhiteSpace } from '@dex/bubl-dash';
import { get, loop, parseNum } from '@dex/bubl-helpers';
import { fillZone } from '../../../functions/sharedZones';
import { getPaceFromKph, getKphFromPace, minusSecond } from '../../../functions/sharedTests';

const CoachingsZoneCreateEdit: React.FC<any> = (props: any) => {

    const { form, data, mode, process, handleSubmit } = props;

    const handleCreateZone = (values) => {

        const oldZone = get(form.defaults, `zones`, []);

        const output = Array.from({ length: values }, (_, index) => {

            const oldZoneData = oldZone[index] || {};

            return {
                name: oldZoneData.name || "Zone " + (index + 1),
                effort: oldZoneData.effort || getArrayElementByIndex(index, ['Endurance', 'Low Tempo', "High Tempo", "Threshold", "Vo2max", "Anaerobic"]),
                ...oldZoneData
            }
        });

        form.handleChange({ name: `zones`, value: output });

    };

    const getArrayElementByIndex = (index, array) => (index < array.length ? array[index] : '');

    const getSession: any = LabTestsApi.getOne(":id");

    const getTestZone = (testId, activity) => {

        getSession.run({
            url: getSession.options.url.replace(":id", testId),
            onComplete: (response) => {

                const lactateData = response.lactate || {};

                const fillBike = activity == "bike";
                const fillRun = activity == "run";
                const fillHr = activity == "hr";
                const fillVo2 = activity == "vo2";

                if (response.activity == "Cycling" && fillBike) {

                    form.handleChange({ reset: true, name: `bikeFTP`, value: get(lactateData, 'measures[0].ftp_speed') })
                    form.handleChange({ reset: true, name: `bikeMax`, value: get(lactateData, 'measures[1].vo2max_speed') })

                } else if (response.activity == "Running" && fillRun) {

                    form.handleChange({ reset: true, name: `runFTP`, value: get(lactateData, 'measures[0].ftp_pace') })
                    form.handleChange({ reset: true, name: `runMax`, value: get(lactateData, 'measures[1].vo2max_pace') })
                    form.handleChange({ reset: true, name: `runSpeedFTP`, value: get(lactateData, 'measures[0].ftp_speed') })
                    form.handleChange({ reset: true, name: `runSpeedMax`, value: get(lactateData, 'measures[1].vo2max_speed') })

                }

                if (fillHr) {

                    form.handleChange({ reset: true, name: `hrFTP`, value: get(lactateData, 'measures[0].ftp_hr') })
                    form.handleChange({ reset: true, name: `hrMax`, value: get(lactateData, 'measures[1].vo2max_hr') })

                }

                if (fillVo2) {

                    form.handleChange({ reset: true, name: `vo2FTP`, value: get(lactateData, 'measures[0].ftp_vo2') })
                    form.handleChange({ reset: true, name: `vo2Max`, value: get(lactateData, 'measures[1].vo2max_vo2') })

                }

            }
        });

    };

    const handleChangePace = (value) => {

        if (!value) return;
        form.handleChange({ reset: true, name: `runSpeedFTP`, value: getKphFromPace(value) });
        form.handleChange({ reset: true, name: `runFTP`, value: value });

    };

    const handleChangeSpeed = (value) => {

        if (!value) return;
        form.handleChange({ reset: true, name: `runFTP`, value: getPaceFromKph(value) });
        form.handleChange({ reset: true, name: `runSpeedFTP`, value: value });

    };

    const handleChangeMaxPace = (value) => {

        if (!value) return;
        form.handleChange({ reset: true, name: `runSpeedMax`, value: getKphFromPace(value) });
        form.handleChange({ reset: true, name: `runMax`, value: value });

    };

    const handleChangeMaxSpeed = (value) => {

        if (!value) return;
        form.handleChange({ reset: true, name: `runMax`, value: getPaceFromKph(value) });
        form.handleChange({ reset: true, name: `runSpeedMax`, value: value });

    };

    return (

        <Panel
            heading={mode === "create" ? "Add Training Zones" : "Edit Training Zones"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row edge gutter={"auto"}>

                    <Col col={{ xs: 8 }}>

                        <FieldText
                            form={form}
                            name={`description`}
                            defaultValue={get(form.values, `description`)}
                            required
                            label={`Training Zones Description`}
                            wrapClass={"manual-input"}
                        />

                    </Col>

                    <Col col={{ xs: 5 }}>

                        <FieldDate
                            form={form}
                            name={`zoneStarting`}
                            defaultValue={get(form.values, `zoneStarting`)}
                            required
                            wrapClass={"manual-input"}
                            label='Zones Starting Date'
                        />

                    </Col>

                    <Col col={{ xs: 5 }}>

                        <FieldNumber
                            form={form}
                            name={`zone`}
                            defaultValue={get(form.values, `zone`)}
                            label={`Zones Needed`}
                            wrapClass={"manual-input"}
                        />

                    </Col>

                    <Col col={{ xs: 3 }} justify='center'>

                        <Space />

                        <Button
                            label="Create Zones"
                            type='faded'
                            size='small'
                            onClick={handleCreateZone.bind(null, get(form.values, `zone`))}
                        />

                    </Col>

                </Row>

                <Seperator
                    heading={`Current Measures`}
                    top={"auto"}
                    bottom={"auto"}
                />

                <Row edge gutter={"auto"}>

                    <Col col={{ xs: 16 }}>

                        <InputTable
                            className="mini-table"
                            columns={[
                                { label: "", key: "activity" },
                                { label: 'Autofill Current FTP & Max From', style: { width: "300px" } },
                                { label: "Current FTP", key: "ftp", style: { width: "200px" }, colSpan: 2 },
                                { label: "Current Max", key: "max", style: { width: "200px" }, colSpan: 2 },
                            ]}
                        >

                            <InputTableRow>

                                <DataTableCell rowSpan={2}>
                                    <strong>
                                        Swim
                                    </strong>
                                </DataTableCell>

                                <DataTableCell rowSpan={2}>

                                    <ButtonRow>

                                        {get(form.values, `testIdA`) &&
                                            <Button
                                                label="Session A"
                                                type="light"
                                                size='small'
                                                onClick={() => getTestZone(get(form.values, `testIdA`), "swim")}
                                            />
                                        }

                                        {get(form.values, `testIdB`) &&
                                            <Button
                                                label="Session B"
                                                type="light"
                                                size='small'
                                                onClick={() => getTestZone(get(form.values, `testIdB`), "swim")}
                                            />
                                        }

                                    </ButtonRow>

                                </DataTableCell>

                                <DataTableCell className={'table-bg-gray table-bdr-left-gray'} colSpan={2}>
                                    FTP [min/100m]
                                </DataTableCell>

                                <DataTableCell className={'table-bg-gray table-bdr-left-gray'}>
                                    Max Pace [200m]
                                </DataTableCell>

                                <InputTableCell className={'table-bg-gray'} />

                            </InputTableRow>

                            <InputTableRow>

                                <InputTableCell className={'table-bdr-left-gray'}>

                                    <FieldTextTime
                                        form={form}
                                        name={`swimFTP`}
                                        defaultValue={get(form.values, `swimFTP`)}
                                        wrapClass={"manual-input"}
                                        placeholder="mm:ss"
                                    />

                                </InputTableCell>

                                <InputTableCell />

                                <InputTableCell className={'table-bdr-left-gray'}>

                                    <FieldTextTime
                                        form={form}
                                        name={`swimMax`}
                                        defaultValue={get(form.values, `swimMax`)}
                                        wrapClass={"manual-input"}
                                        placeholder="mm:ss"
                                    />

                                </InputTableCell>

                                <InputTableCell />

                            </InputTableRow>

                            <InputTableRow>

                                <DataTableCell rowSpan={2}>
                                    <strong>
                                        Bike
                                    </strong>
                                </DataTableCell>

                                <DataTableCell rowSpan={2}>

                                    <ButtonRow>

                                        {get(form.values, `testIdA`) &&
                                            <Button
                                                label="Session A"
                                                type="light"
                                                size='small'
                                                onClick={() => getTestZone(get(form.values, `testIdA`), "bike")}
                                            />
                                        }

                                        {get(form.values, `testIdB`) &&
                                            <Button
                                                label="Session B"
                                                type="light"
                                                size='small'
                                                onClick={() => getTestZone(get(form.values, `testIdB`), "bike")}
                                            />
                                        }

                                    </ButtonRow>

                                </DataTableCell>

                                <DataTableCell className={'table-bg-gray table-bdr-left-gray'} colSpan={2}>
                                    FTP
                                </DataTableCell>

                                <DataTableCell className={'table-bg-gray table-bdr-left-gray'}>
                                    Max [4-5 min]
                                </DataTableCell>

                                <InputTableCell className={'table-bg-gray'} />

                            </InputTableRow>

                            <InputTableRow>

                                <InputTableCell className={'table-bdr-left-gray'}>
                                    <FieldNumber
                                        form={form}
                                        name={`bikeFTP`}
                                        defaultValue={get(form.values, `bikeFTP`)}
                                        wrapClass={"manual-input"}
                                        suffix=" W"
                                        dynamicValue
                                    />
                                </InputTableCell>

                                <InputTableCell />

                                <InputTableCell className={'table-bdr-left-gray'}>
                                    <FieldNumber
                                        dynamicValue
                                        form={form}
                                        name={`bikeMax`}
                                        defaultValue={get(form.values, `bikeMax`)}
                                        wrapClass={"manual-input"}
                                        label=''
                                        suffix=" W"
                                    />
                                </InputTableCell>

                                <InputTableCell />

                            </InputTableRow>

                            <InputTableRow>

                                <DataTableCell rowSpan={2}>
                                    <strong>
                                        Run
                                    </strong>
                                </DataTableCell>

                                <DataTableCell rowSpan={2}>

                                    <ButtonRow>

                                        {get(form.values, `testIdA`) &&
                                            <Button
                                                label="Session A"
                                                type="light"
                                                size='small'
                                                onClick={() => getTestZone(get(form.values, `testIdA`), "run")}
                                            />
                                        }

                                        {get(form.values, `testIdB`) &&
                                            <Button
                                                label="Session B"
                                                type="light"
                                                size='small'
                                                onClick={() => getTestZone(get(form.values, `testIdB`), "run")}
                                            />
                                        }

                                    </ButtonRow>

                                </DataTableCell>

                                <DataTableCell className={'table-bg-gray table-bdr-left-gray'}>
                                    FTP Speed [kph]
                                </DataTableCell>
                                <DataTableCell className={'table-bg-gray'}>
                                    FTP Pace [min/km]
                                </DataTableCell>

                                <DataTableCell className={'table-bg-gray table-bdr-left-gray'}>
                                    Max Speed[kph]
                                </DataTableCell>

                                <InputTableCell className={'table-bg-gray'} >
                                    Max Pace [1-1.5km]
                                </InputTableCell>

                            </InputTableRow>

                            <InputTableRow>

                                <InputTableCell className={'table-bdr-left-gray'} >

                                    <FieldNumber
                                        dynamicKey
                                        form={form}
                                        name={`runSpeedFTP`}
                                        defaultValue={get(form.values, `runSpeedFTP`)}
                                        wrapClass={"manual-input"}
                                        suffix=" kph"
                                        onChange={handleChangeSpeed}
                                    />

                                </InputTableCell>

                                <InputTableCell>

                                    <FieldTextTime
                                        dynamicKey
                                        form={form}
                                        name={`runFTP`}
                                        defaultValue={get(form.values, `runFTP`)}
                                        wrapClass={"manual-input"}
                                        placeholder="mm:ss"
                                        onChange={handleChangePace}
                                    />

                                </InputTableCell>

                                <InputTableCell className={'table-bdr-left-gray'} >

                                    <FieldNumber
                                        dynamicKey
                                        form={form}
                                        name={`runSpeedMax`}
                                        defaultValue={get(form.values, `runSpeedMax`)}
                                        wrapClass={"manual-input"}
                                        suffix=" kph"
                                        onChange={handleChangeMaxSpeed}
                                    />

                                </InputTableCell>

                                <InputTableCell  >

                                    <FieldTextTime
                                        dynamicKey
                                        form={form}
                                        name={`runMax`}
                                        defaultValue={get(form.values, `runMax`)}
                                        wrapClass={"manual-input"}
                                        placeholder="mm:ss"
                                        onChange={handleChangeMaxPace}
                                    />

                                </InputTableCell>

                            </InputTableRow>

                            <InputTableRow>

                                <DataTableCell rowSpan={2}>
                                    <strong>
                                        HR
                                    </strong>
                                </DataTableCell>

                                <DataTableCell rowSpan={2}>

                                    <ButtonRow>

                                        {get(form.values, `testIdA`) &&
                                            <Button
                                                label="Session A"
                                                type="light"
                                                size='small'
                                                onClick={() => getTestZone(get(form.values, `testIdA`), "hr")}
                                            />
                                        }

                                        {get(form.values, `testIdB`) &&
                                            <Button
                                                label="Session B"
                                                type="light"
                                                size='small'
                                                onClick={() => getTestZone(get(form.values, `testIdB`), "hr")}
                                            />
                                        }

                                    </ButtonRow>

                                </DataTableCell>

                                <DataTableCell className={'table-bg-gray table-bdr-left-gray'} colSpan={2}>
                                    FTP
                                </DataTableCell>

                                <DataTableCell className={'table-bg-gray table-bdr-left-gray'}>
                                    Max
                                </DataTableCell>

                                <InputTableCell className={'table-bg-gray'} />

                            </InputTableRow>

                            <InputTableRow>

                                <InputTableCell className={'table-bdr-left-gray'}>

                                    <FieldNumber
                                        form={form}
                                        name={`hrFTP`}
                                        defaultValue={get(form.values, `hrFTP`)}
                                        wrapClass={"manual-input"}
                                        suffix=" bpm"
                                        dynamicValue
                                    />

                                </InputTableCell>

                                <InputTableCell />

                                <InputTableCell className={'table-bdr-left-gray'}>

                                    <FieldNumber
                                        dynamicValue
                                        form={form}
                                        name={`hrMax`}
                                        defaultValue={get(form.values, `hrMax`)}
                                        wrapClass={"manual-input"}
                                        suffix=" bpm"
                                    />

                                </InputTableCell>

                                <InputTableCell />

                            </InputTableRow>

                            <InputTableRow>

                                <DataTableCell rowSpan={2}>
                                    <strong>
                                        VO2
                                    </strong>
                                </DataTableCell>

                                <DataTableCell rowSpan={2}>

                                    <ButtonRow>

                                        {get(form.values, `testIdA`) &&
                                            <Button
                                                label="Session A"
                                                type="light"
                                                size='small'
                                                onClick={() => getTestZone(get(form.values, `testIdA`), "vo2")}
                                            />
                                        }

                                        {get(form.values, `testIdB`) &&
                                            <Button
                                                label="Session B"
                                                type="light"
                                                size='small'
                                                onClick={() => getTestZone(get(form.values, `testIdB`), "vo2")}
                                            />
                                        }

                                    </ButtonRow>

                                </DataTableCell>

                                <DataTableCell className={'table-bg-gray table-bdr-left-gray'} colSpan={2}>
                                    FTP
                                </DataTableCell>

                                <DataTableCell className={'table-bg-gray table-bdr-left-gray'}>
                                    Max
                                </DataTableCell>

                                <InputTableCell className={'table-bg-gray'} />

                            </InputTableRow>

                            <InputTableRow>

                                <InputTableCell className={'table-bdr-left-gray'}>

                                    <FieldNumber
                                        form={form}
                                        name={`vo2FTP`}
                                        defaultValue={get(form.values, `vo2FTP`)}
                                        wrapClass={"manual-input w-200"}
                                        suffix=" ml/kg*min"
                                        dynamicValue
                                    />

                                </InputTableCell>

                                <InputTableCell />

                                <InputTableCell className={'table-bdr-left-gray'}>

                                    <FieldNumber
                                        dynamicValue
                                        form={form}
                                        name={`vo2Max`}
                                        defaultValue={get(form.values, `vo2Max`)}
                                        wrapClass={"manual-input w-200"}
                                        suffix=" ml/kg*min"
                                    />

                                </InputTableCell>

                                <InputTableCell />

                            </InputTableRow>

                        </InputTable>

                    </Col>

                    <Col col={{ xs: 8 }}>

                        <Row edge gutter={8}>

                            <Col col={{ xs: 24 }}>

                                <FieldRelationship
                                    form={form}
                                    name={`testIdA`}
                                    defaultValue={get(form.values, `testIdA`)}
                                    label={`Previous Test Session A`}
                                    info={"Get FTP and Max Measures From Previous Test Session"}
                                    wrapClass={"manual-input"}
                                    preload={true}
                                    endpoint="LabTests"
                                    where={{ athleteId: data.athleteId }}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldRelationship
                                    form={form}
                                    name={`testIdB`}
                                    defaultValue={get(form.values, `testIdB`)}
                                    label={`Previous Test Session B`}
                                    info={"Get FTP and Max Measures From Previous Test Session"}
                                    wrapClass={"manual-input"}
                                    preload={true}
                                    endpoint="LabTests"
                                    where={{ athleteId: data.athleteId }}
                                />

                            </Col>

                        </Row>

                    </Col>

                </Row>

                <Seperator
                    heading={`Zones`}
                    top={"auto"}
                    bottom={"auto"}
                />

                <ZonesTable
                    form={form}
                />

                <ButtonRow>
                    <Button
                        label="Save"
                        type="primary"
                    />
                </ButtonRow>

            </Form>

        </Panel>

    );
}

const ZonesTable: React.FC<any> = (props: any) => {

    const { form } = props;

    const runType = get(form.values, `runType`, "speed");
    const swimLevel = get(form.values, `swimLevel`, "fair");

    const hide = get(form.values, `hide`, []);
    const hideSwim = hide.includes("swim");
    const hideBike = hide.includes("bike");
    const hideRun = hide.includes("run");
    const hideHr = hide.includes("hr");

    const getSession: any = LabTestsApi.getOne(":id");

    const getTestZone = (testId, activity) => {

        getSession.run({
            url: getSession.options.url.replace(":id", testId),
            onComplete: (response) => {

                loop(response?.lactate?.zones, (zone, key) => {
                    if (activity == "bike") {
                        form.handleChange({ reset: true, name: `zones[${key}].bikeFrom`, value: parseNum(zone.powerLower) ? zone.powerLower : null })
                        form.handleChange({ reset: true, name: `zones[${key}].bikeTo`, value: parseNum(zone.powerUpper) ? zone.powerUpper : null })
                    }
                    if (activity == "run") {
                        form.handleChange({ reset: true, name: `zones[${key}].runFromSpeed`, value: parseNum(zone.powerLower) ? zone.powerLower : null })
                        form.handleChange({ reset: true, name: `zones[${key}].runToSpeed`, value: parseNum(zone.powerUpper) ? zone.powerUpper : null })
                        form.handleChange({ reset: true, name: `zones[${key}].runFromPace`, value: parseNum(zone.pace_adj_upper) ? zone.pace_adj_upper : null })
                        form.handleChange({ reset: true, name: `zones[${key}].runToPace`, value: parseNum(zone.pace_adj_lower) ? zone.pace_adj_lower : null })
                    }
                    if (activity == "hr") {
                        form.handleChange({ reset: true, name: `zones[${key}].hrFrom`, value: parseNum(zone.hrLower) ? zone.hrLower : null })
                        form.handleChange({ reset: true, name: `zones[${key}].hrTo`, value: parseNum(zone.hrUpper) ? zone.hrUpper : null })
                    }
                });

            }
        });

    };

    useUpdateEffect(() => {
        fillZone("swim", form, swimLevel);
    }, [swimLevel])

    const handleFillZone = (type, activity) => {

        if (activity == "swim") {

            if (type == "current") {

                fillZone("swim", form, swimLevel);

            }

        }

        if (activity == "bike") {

            if (type == "current") {

                fillZone("bike", form, {})

            } else {

                getTestZone(get(form.values, type), "bike")

            }

        }

        if (activity == "run") {

            if (type == "current") {

                fillZone("run", form, {})

            } else {

                getTestZone(get(form.values, type), "run")

            }
        }

        if (activity == "hr") {

            if (type == "current") {

                fillZone("hr", form, {})

            } else {

                getTestZone(get(form.values, type), "hr");

            }

        }

    };

    const zones = form.values.zones?.length || 0;

    const handleChange = (index, key, value) => {

        let name = "";

        if (!value) return;
        if (index + 1 === zones) return;

        const originalValue = value;

        if (key === "hrTo") {

            name = `zones[${index + 1}].hrFrom`;
            value = value + 1;

        } else if (key === "runToSpeed") {

            name = `zones[${index + 1}].runFromSpeed`;
            value = (value + 0.10).toFixed(1);

            //also change pace
            form.handleChange({ name: `zones[${index}].runFromPace`, value: getPaceFromKph(originalValue), reset: true, silent: true });
            form.handleChange({ name: `zones[${index + 1}].runToPace`, value: minusSecond(getPaceFromKph(originalValue)), reset: true, silent: true });

        } else if (key === "runFromPace") {

            name = `zones[${index + 1}].runToPace`;
            value = minusSecond(value);

            //also change speed
            form.handleChange({ name: `zones[${index}].runToSpeed`, value: getKphFromPace(originalValue), reset: true, silent: true });
            form.handleChange({ name: `zones[${index + 1}].runFromSpeed`, value: (getKphFromPace(originalValue) + 0.10).toFixed(1), reset: true, silent: true });

        } else if (key === "bikeTo") {

            name = `zones[${index + 1}].bikeFrom`;
            value = value + 1;

        } else if (key === "swimTo") {

            name = `zones[${index + 1}].swimFrom`;
            value = minusSecond(value);

        }

        if (!name) return;

        form.handleChange({ name: name, value: value, reset: true, silent: true });

    };

    return (

        <FieldTable
            form={form}
            name={`zones`}
            defaultValue={get(form.values, `zones`)}
            defaultValues={{}}
            head={(
                <InputTableHead >

                    <InputTableHeadRow>

                        <InputTableHeadCell
                            label="#"
                        />

                        <InputTableHeadCell
                            label="Action"
                        />

                        <InputTableHeadCell />

                        <InputTableHeadCell
                            label="Zones"
                            colSpan={2}
                            style={{ minWidth: "300px" }}
                        />

                        {!hideSwim &&
                            <InputTableHeadCell
                                className="table-bdr-left-gray"
                                label="Swim"
                                colSpan={2}
                                style={{ minWidth: "300px" }}
                                alignMiddle={true}
                            />
                        }

                        {!hideBike &&
                            <InputTableHeadCell
                                className="table-bdr-left-gray"
                                label="Bike"
                                colSpan={2}
                                style={{ minWidth: "300px" }}
                                alignMiddle={true}
                            />
                        }

                        {!hideRun &&
                            <InputTableHeadCell
                                className="table-bdr-left-gray"
                                label="Run"
                                colSpan={2}
                                style={{ minWidth: "300px" }}
                                alignMiddle
                            />
                        }

                        {!hideHr &&
                            <InputTableHeadCell
                                className="table-bdr-left-gray"
                                label="HR"
                                colSpan={2}
                                style={{ minWidth: "300px" }}
                                alignMiddle
                            />
                        }

                    </InputTableHeadRow>

                    <InputTableHeadRow>

                        <InputTableHeadCell
                            label="Autofill Zones From"
                            alignEnd
                            colSpan={5}
                        />

                        {!hideSwim &&

                            <InputTableHeadCell
                                className="table-bdr-left-gray"
                                label={
                                    <ButtonRow>
                                        <Button
                                            label={"Current"}
                                            type={"light"}
                                            size={"small"}
                                            onClick={handleFillZone.bind(null, "current", "swim")}
                                        />
                                    </ButtonRow>
                                }
                                colSpan={2}
                            // alignMiddle={true}
                            />
                        }

                        {!hideBike &&

                            <InputTableHeadCell
                                className="table-bdr-left-gray"
                                label={
                                    <ButtonRow>
                                        {get(form.values, `testIdA`) &&
                                            <Button
                                                label={"Session A"}
                                                type={"light"}
                                                size={"small"}
                                                onClick={handleFillZone.bind(null, "testIdA", "bike")}
                                            />
                                        }
                                        {get(form.values, `testIdB`) &&
                                            <Button
                                                label={"Session B"}
                                                type={"light"}
                                                size={"small"}
                                                onClick={handleFillZone.bind(null, "testIdB", "bike")}
                                            />
                                        }
                                        <Button
                                            label={"Current"}
                                            type={"light"}
                                            size={"small"}
                                            onClick={handleFillZone.bind(null, "current", "bike")}
                                        />
                                    </ButtonRow>
                                }
                                colSpan={2}
                            // alignMiddle={true}
                            />
                        }

                        {!hideRun &&

                            <InputTableHeadCell
                                className="table-bdr-left-gray"
                                label={
                                    <ButtonRow>
                                        {get(form.values, `testIdA`) &&
                                            <Button
                                                label={"Session A"}
                                                type={"light"}
                                                size={"small"}
                                                onClick={handleFillZone.bind(null, "testIdA", "run")}
                                            />
                                        }
                                        {get(form.values, `testIdB`) &&
                                            <Button
                                                label={"Session B"}
                                                type={"light"}
                                                size={"small"}
                                                onClick={handleFillZone.bind(null, "testIdB", "run")}
                                            />
                                        }
                                        <Button
                                            label={"Current"}
                                            type={"light"}
                                            size={"small"}
                                            onClick={handleFillZone.bind(null, "current", "run")}
                                        />
                                    </ButtonRow>
                                }
                                colSpan={2}
                            // alignMiddle={true}
                            />
                        }

                        {!hideHr &&

                            <InputTableHeadCell
                                className="table-bdr-left-gray"
                                label={
                                    <ButtonRow>
                                        {get(form.values, `testIdA`) &&
                                            <Button
                                                label={"Session A"}
                                                type={"light"}
                                                size={"small"}
                                                onClick={handleFillZone.bind(null, "testIdA", "hr")}
                                            />
                                        }
                                        {get(form.values, `testIdB`) &&
                                            <Button
                                                label={"Session B"}
                                                type={"light"}
                                                size={"small"}
                                                onClick={handleFillZone.bind(null, "testIdB", "hr")}
                                            />
                                        }
                                        <Button
                                            label={"Current"}
                                            type={"light"}
                                            size={"small"}
                                            onClick={handleFillZone.bind(null, "current", "hr")}
                                        />
                                    </ButtonRow>
                                }
                                colSpan={2}
                            // alignMiddle={true}
                            />

                        }

                    </InputTableHeadRow>

                    <InputTableHeadRow>

                        <InputTableHeadCell
                            colSpan={5}
                            label={
                                <Row>
                                    <Col justify='center'>Hide Columns: <WhiteSpace /></Col>
                                    <Col>
                                        <FieldCheckbox
                                            form={form}
                                            name={`hide`}
                                            defaultValue={get(form.defaults, `hide`)}
                                            options={[
                                                { label: "Swim", value: "swim" },
                                                { label: "Bike", value: "bike" },
                                                { label: "Run", value: "run" },
                                                { label: "HR", value: "hr" },
                                            ]}

                                        />
                                    </Col>
                                </Row>
                            }
                        />

                        {!hideSwim &&

                            <InputTableHeadCell
                                className="table-bdr-left-gray"
                                alignMiddle
                                label={
                                    <FieldRadio
                                        form={form}
                                        name={`swimLevel`}
                                        defaultValue={get(form.defaults, `swimLevel`, "fair")}
                                        options={[
                                            { label: "Strong", value: "strong" },
                                            { label: "Fair", value: "fair" },
                                            { label: "Weak", value: "weak" },
                                        ]}
                                    />
                                }
                                colSpan={2}
                            // alignMiddle={true}
                            />
                        }

                        {!hideBike &&

                            <InputTableHeadCell
                                className="table-bdr-left-gray"
                                colSpan={2}
                                style={{ minWidth: "300px" }}
                            // alignMiddle={true}
                            />
                        }

                        {!hideRun &&

                            <InputTableHeadCell
                                className="table-bdr-left-gray"
                                alignMiddle
                                label={
                                    <div className={`${hideRun ? "hidden" : ""}`}>
                                        <FieldRadio
                                            form={form}
                                            name={`runType`}
                                            defaultValue={get(form.defaults, `runType`, "speed")}
                                            options={[
                                                { label: "Speed", value: "speed" },
                                                { label: "Pace", value: "pace" },
                                            ]}
                                        />
                                    </div>
                                }
                                colSpan={2}
                            // alignMiddle
                            />
                        }

                        {!hideHr &&

                            <InputTableHeadCell
                                className="table-bdr-left-gray"
                                colSpan={2}
                            />

                        }

                    </InputTableHeadRow>

                </InputTableHead>
            )}
        >

            {(get(form.values, `zones`) || []).map((row, i) => (

                <React.Fragment key={i}>

                    <DataTableCell>
                        {i <= 5 &&
                            <img
                                src={require(`../../../assets/images/TrainingCard/Zone ${i + 1}.png`)}
                                style={{ width: "50px", height: "50px", objectFit: "scale-down" }}
                                alt={`${i + 1}.png`}
                            />
                        }
                    </DataTableCell>

                    <InputTableCell>
                        <FieldText
                            form={form}
                            name={`zones[${i}].name`}
                            defaultValue={get(form.values, `zones[${i}].name`, "Zone " + (i + 1))}
                            wrapClass={"manual-input"}
                            tabIndex={1}
                        />
                    </InputTableCell>

                    <InputTableCell>
                        <FieldText
                            form={form}
                            name={`zones[${i}].effort`}
                            defaultValue={get(form.values, `zones[${i}].effort`)}
                            wrapClass={"manual-input"}
                            tabIndex={2}
                        />
                    </InputTableCell>

                    {!hideSwim &&
                        <>
                            <InputTableCell className="table-bdr-left-gray" >
                                <FieldTextTime
                                    form={form}
                                    name={`zones[${i}].swimFrom`}
                                    defaultValue={get(form.values, `zones[${i}].swimFrom`)}
                                    key={get(form.values, `zones[${i}].swimFrom`)}
                                    wrapClass={i == 0 ? "manual-input" : ""}
                                    disabled={i !== 0 ? true : false}
                                    placeholder={(!i && !get(form.values, `zones[${i}].swimFrom`)) ? "<" : "mm:ss"}
                                    format="mm:ss"
                                    tabIndex={3}
                                />
                            </InputTableCell>

                            <InputTableCell>
                                <FieldTextTime
                                    form={form}
                                    name={`zones[${i}].swimTo`}
                                    defaultValue={get(form.values, `zones[${i}].swimTo`)}
                                    key={get(form.values, `zones[${i}].swimTo`)}
                                    wrapClass={"manual-input"}
                                    placeholder={(i + 1 === zones && !get(form.values, `zones[${i}].swimTo`)) ? ">" : "mm:ss"}
                                    format="mm:ss"
                                    onChange={handleChange.bind(null, i, "swimTo")}
                                    tabIndex={3}
                                />
                            </InputTableCell>
                        </>
                    }

                    {!hideBike &&
                        <>
                            <InputTableCell className="table-bdr-left-gray" >
                                <FieldNumber
                                    dynamicValue
                                    form={form}
                                    name={`zones[${i}].bikeFrom`}
                                    defaultValue={get(form.values, `zones[${i}].bikeFrom`)}
                                    suffix=" W"
                                    placeholder={(!i && !get(form.values, `zones[${i}].bikeFrom`)) ? "<" : ""}
                                    disabled={i !== 0 ? true : false}
                                    wrapClass={i == 0 ? "manual-input" : ""}
                                    tabIndex={4}
                                />
                            </InputTableCell>

                            <InputTableCell>
                                <FieldNumber
                                    dynamicValue
                                    form={form}
                                    name={`zones[${i}].bikeTo`}
                                    defaultValue={get(form.values, `zones[${i}].bikeTo`)}
                                    placeholder={(i + 1 === zones && !get(form.values, `zones[${i}].bikeTo`)) ? ">" : ""}
                                    suffix=" W"
                                    wrapClass={"manual-input"}
                                    onChange={handleChange.bind(null, i, "bikeTo")}
                                    tabIndex={4}
                                />
                            </InputTableCell>
                        </>
                    }

                    {!hideRun &&
                        <>
                            <InputTableCell className="table-bdr-left-gray">
                                <FieldTextTime
                                    key={get(form.values, `zones[${i}].runToPace`)}
                                    form={form}
                                    name={`zones[${i}].runToPace`}
                                    defaultValue={get(form.values, `zones[${i}].runToPace`)}
                                    placeholder={(!i && !get(form.values, `zones[${i}].runToPace`)) ? ">" : "mm:ss"}
                                    format="mm:ss"
                                    wrapClass={runType === "pace" ? "manual-input" : "hidden"}
                                    disabled={i !== 0 ? true : false}
                                    tabIndex={5}
                                />
                                <FieldNumber
                                    key={get(form.values, `zones[${i}].runFromSpeed`)}
                                    form={form}
                                    name={`zones[${i}].runFromSpeed`}
                                    defaultValue={get(form.values, `zones[${i}].runFromSpeed`)}
                                    suffix={(!i && !get(form.values, `zones[${i}].runFromSpeed`)) ? "<" : " kph"}
                                    wrapClass={runType === "speed" ? "manual-input" : "hidden"}
                                    disabled={i !== 0 ? true : false}
                                    tabIndex={6}
                                />
                            </InputTableCell>

                            <InputTableCell>
                                <FieldTextTime
                                    key={get(form.values, `zones[${i}].runFromPace`)}
                                    form={form}
                                    name={`zones[${i}].runFromPace`}
                                    defaultValue={get(form.values, `zones[${i}].runFromPace`)}
                                    wrapClass={runType === "pace" ? "manual-input" : "hidden"}
                                    placeholder={(i + 1 === zones && !get(form.values, `zones[${i}].runFromPace`)) ? "<" : "mm:ss"}
                                    format="mm:ss"
                                    onChange={handleChange.bind(null, i, "runFromPace")}
                                    tabIndex={5}
                                />
                                <FieldNumber
                                    key={get(form.values, `zones[${i}].runToSpeed`)}
                                    form={form}
                                    name={`zones[${i}].runToSpeed`}
                                    defaultValue={get(form.values, `zones[${i}].runToSpeed`)}
                                    suffix={(i + 1 === zones && !get(form.values, `zones[${i}].runToSpeed`)) ? ">" : " kph"}
                                    wrapClass={runType === "speed" ? "manual-input" : "hidden"}
                                    onChange={handleChange.bind(null, i, "runToSpeed")}
                                    tabIndex={6}
                                />
                            </InputTableCell>
                        </>
                    }

                    {!hideHr &&
                        <>

                            <InputTableCell className="table-bdr-left-gray" >

                                {i + 1 !== zones &&

                                    <FieldNumber
                                        dynamicValue
                                        form={form}
                                        name={`zones[${i}].hrFrom`}
                                        defaultValue={get(form.values, `zones[${i}].hrFrom`)}
                                        wrapClass={i == 0 ? "manual-input" : ""}
                                        disabled={i == 0 ? false : true}
                                        suffix={(!i && !get(form.values, `zones[${i}].hrFrom`)) ? "<" : " bpm"}
                                        tabIndex={7}
                                    />

                                }

                            </InputTableCell>

                            <InputTableCell>

                                {i + 1 !== zones &&

                                    <FieldNumber
                                        dynamicValue
                                        form={form}
                                        name={`zones[${i}].hrTo`}
                                        defaultValue={get(form.values, `zones[${i}].hrTo`)}
                                        wrapClass={get(form.values, `zones`).length - 1 !== i ? "manual-input" : ""}
                                        disabled={get(form.values, `zones`).length - 1 == i ? true : false}
                                        onChange={handleChange.bind(null, i, "hrTo")}
                                        suffix=" bpm"
                                        tabIndex={7}
                                    />

                                }

                            </InputTableCell>

                        </>
                    }

                </React.Fragment>

            ))}

        </FieldTable>

    )
}

export default CoachingsZoneCreateEdit;